import { Msal2Config } from "@microsoft/mgt-msal2-provider";

const useLocalhost = !!process.env.REACT_APP_USE_LOCALHOST;

// const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
// const redirectUri = process.env.REACT_APP_REDIRECT_URI;
// const clientId = process.env.REACT_APP_CLIENT_ID ?? "";
// const authority = process.env.REACT_APP_AUTHORITY;
// const scope = process.env.REACT_APP_SCOPE ?? "";

type Config = {
  env: EnvVariables;
  msal2Config: Msal2Config;
  loginRequest: {
    scopes: string[];
  };
  apiURL: {
    baseURL: string;
  };
};

interface EnvVariables {
  appInsightsConnectionString: string;
  azureSubscriptionId: string;
  azureTenantId: string;
  cloudRoleName: string;
  environment: "dev" | "ppe" | "prod";
  siufScenarioId: string;
  suifBaseUrl: string;
  uifBaseUrl: string;
  graphBaseUrl: string;
  cloudBuildBaseUrl: string;
  easiEndpoint: string;
  esaiDeploymentName: string;
}

let config: Config | undefined;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const initConfig = (env: any) => {
  const apiEndpoint = env.REACT_APP_API_ENDPOINT;
  const clientId = env.REACT_APP_CLIENT_ID ?? "";
  const authority = env.REACT_APP_AUTHORITY;
  const scope = env.REACT_APP_SCOPE ?? "";

  const msal2Config: Msal2Config = {
    clientId,
    authority,
    redirectUri: window.location.origin,
  };

  const loginRequest = {
    scopes: [scope],
  };

  const apiURL = {
    baseURL: useLocalhost ? "https://localhost:6001/api" : apiEndpoint,
  };

  const envVariables: EnvVariables = {
    appInsightsConnectionString: env.REACT_APP_APP_INSIGHTS_CONNECTION_STRING,
    azureSubscriptionId: env.REACT_APP_AZURE_SUBSCRIPTION_ID,
    azureTenantId: env.REACT_APP_AZURE_TENANT_ID,
    cloudRoleName: env.REACT_APP_CLOUD_ROLE_NAME,
    environment: env.REACT_APP_ENVIRONMENT,
    siufScenarioId: env.REACT_APP_SIUF_SCENARIO_ID,
    suifBaseUrl: env.REACT_APP_SIUF_BASE_URL,
    uifBaseUrl: env.REACT_APP_UIF_BASE_URL,
    graphBaseUrl: env.REACT_APP_GRAPH_BASE_URL,
    cloudBuildBaseUrl: env.CLOUD_BUILD_BASE_URL,
    easiEndpoint: env.ESAI_ENDPOINT,
    esaiDeploymentName: env.ESAI_DEPLOYMENT_NAME,
  };

  config = {
    env: envVariables,
    msal2Config,
    loginRequest,
    apiURL,
  };
};

export const useConfig = (): Config => config ?? ({} as Config);
