import "index.css";
import React, { Suspense, lazy } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { initializeIcons } from "@fluentui/react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import { ConfigInitializer } from "ConfigInitializer";
import { ErrorFallback } from "ErrorFallback";
import { FluentProvider, webDarkTheme, webLightTheme } from "@fluentui/react-components";
import { getStorage } from "services/localStorageService";
import { FullPageSpinner } from "components/common/FullPageSpinner";

const App = lazy(() => import("./App"));
const AppProviders = lazy(() => import("./AppProviders"));

initializeIcons();

const darkMode = !!getStorage<boolean>("darkMode");

ReactDOM.render(
  <React.StrictMode>
    <FluentProvider theme={darkMode ? webDarkTheme : webLightTheme}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <ConfigInitializer>
          <Suspense fallback={<FullPageSpinner size="large" label="Loading application..." />}>
            <AppProviders>
              <App />
            </AppProviders>
          </Suspense>
        </ConfigInitializer>
      </ErrorBoundary>
    </FluentProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
