import { Providers, ProviderState } from "@microsoft/mgt-element";
import { User } from "@microsoft/microsoft-graph-types";
import { setUpAuthInterceptor } from "api/api";
import { useEffect, useState } from "react";

let userInfo: User | undefined;
let userInfoReqested = false;

export const useLogin = () => {
  const [signedIn, setSignedIn] = useState<boolean>(Providers.globalProvider.state === ProviderState.SignedIn);
  const stateChangedHandler = () => setSignedIn(false);
  const [user, setUser] = useState<User | undefined>(userInfo);

  useEffect(() => {
    const updateState = () => {
      setSignedIn(Providers.globalProvider && Providers.globalProvider.state === ProviderState.SignedIn);
      if (Providers.globalProvider.state === ProviderState.SignedIn) {
        setUpAuthInterceptor();
      }
    };
    Providers.onProviderUpdated(updateState);

    if (signedIn && !userInfoReqested) {
      userInfoReqested = true;
      setUpAuthInterceptor();
      Providers.globalProvider.graph
        .api("/me")
        .select("displayName,givenName,id,mail,mailNickname")
        .get()
        .then((data) => {
          userInfo = data;
          setUser(userInfo);
        });
    }

    return () => {
      Providers.removeProviderUpdatedListener(updateState);
      Providers.globalProvider.removeStateChangedHandler(stateChangedHandler);
    };
  }, [signedIn]);

  if (signedIn && user) {
    return { loading: false, signedIn, user };
  }

  if (Providers.globalProvider.state === ProviderState.SignedOut) {
    Providers.globalProvider.login?.();
    return { loading: false, signedIn };
  }

  if (Providers.globalProvider.state === ProviderState.Loading) {
    Providers.globalProvider.onStateChanged(stateChangedHandler);
    return { loading: true, signedIn };
  }

  return { loading: false, signedIn: true, user };
};

export interface UserInfo {
  businessPhones: string[];
  displayName: string;
  givenName: string;
  id: string;
  jobTitle: string;
  mail: string;
  mailNickname: string;
  mobilePhone?: string;
  officeLocation: string;
  preferredLanguage?: string;
  surname: string;
  userPrincipalName: string;
}
