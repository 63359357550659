/* eslint-disable @typescript-eslint/no-explicit-any */
import { Providers } from "@microsoft/mgt-element";
import axios, { AxiosRequestConfig } from "axios";
import { useConfig } from "../services/authConfig";

// export const apiClient: AxiosInstance = axios.create(apiURL);
export const apiClient = {
  get(url: string, config: AxiosRequestConfig | undefined = {}) {
    const { apiURL } = useConfig();
    return axios.request({
      baseURL: apiURL.baseURL,
      method: "get",
      url,
      ...config,
    });
  },

  post(url: string, data?: unknown) {
    const { apiURL } = useConfig();
    return axios.request({
      baseURL: apiURL.baseURL,
      method: "post",
      url,
      data,
    });
  },
};

axios.interceptors.response.use(
  (response: any) => {
    return response;
  },
  (error: any) => {
    if (error.response && error.response.data) {
      return error.response.data;
    }
    return error.message;
  },
);

export function setUpAuthInterceptor() {
  const { loginRequest } = useConfig();

  axios.interceptors.request.use(async (config: any) => {
    const accessToken = await Providers.globalProvider.getAccessTokenForScopes(...loginRequest.scopes);
    config.headers["Authorization"] = `Bearer ${accessToken}`;
    return config;
  });
}
