import { Spinner, SpinnerProps, makeStyles } from "@fluentui/react-components";

export const FullPageSpinner = (props: SpinnerProps) => {
  const styles = useStyles();
  return (
    <div className={styles.root}>
      <Spinner {...props} />
    </div>
  );
};

const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  },
});
