export const getStorage = <T>(key: string): T | null => {
  const data = localStorage.getItem(key);
  if (!data) {
    return null;
  }
  return JSON.parse(data);
};

export const setStorage = (key: string, data: unknown): void => {
  localStorage.setItem(key, JSON.stringify(data));
};
