import { PropsWithChildren, useEffect, useState } from "react";
import { initConfig, useConfig } from "./services/authConfig";
import { Providers } from "@microsoft/mgt-react";
import { Msal2Provider } from "@microsoft/mgt-msal2-provider";
import { loadAppInsights } from "services/applicationInsights";
import { FullPageSpinner } from "components/common/FullPageSpinner";

export const ConfigInitializer = ({ children }: PropsWithChildren<unknown>) => {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    import(/* webpackChunkName: "config" */ `./config/config.json`)
      .then(({ default: config }) => {
        initConfig(config);
        const { msal2Config } = useConfig();
        const provider = new Msal2Provider(msal2Config);

        Providers.globalProvider = provider;
        loadAppInsights();
      })
      .then(() => {
        setInitialized(true);
      });
  }, []);

  if (!initialized) {
    return <FullPageSpinner size="large" label="Loading config..." />;
  }

  return <>{children}</>;
};
